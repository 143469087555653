import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconVolumeOff from './icons/icon-volume-off'
import IconVolumeOn from './icons/icon-volume-on'
import styles from './control-volume.css'

class ControlVolume extends React.PureComponent {
  state = {
    isMuted: true
  }

  render() {
    const {
      wrapperClassName, iconClassName, mute, unmute
    } = this.props
    const { isMuted } = this.state
    return (
      <div
        className={classnames(wrapperClassName, styles.wrapper)}
        onClick={() => {
          if (isMuted) {
            unmute()
            this.setState({ isMuted: false })
          } else {
            mute()
            this.setState({ isMuted: true })
          }
        }}
      >
        <IconVolumeOff
          className={classnames(iconClassName, {
            [styles.hide]: !isMuted
          })}
        />
        <IconVolumeOn
          className={classnames(iconClassName, {
            [styles.hide]: isMuted
          })}
        />
      </div>
    )
  }
}

ControlVolume.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
  mute: PropTypes.func.isRequired,
  unmute: PropTypes.func.isRequired
}

export default ControlVolume
