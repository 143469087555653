import React from 'react'
import ReactImgix from 'react-imgix'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './image.css'

class Image extends React.PureComponent {
  render() {
    const { isHidden, image } = this.props
    return (
      <ReactImgix
        className={classnames(styles.image, {
          [styles.hidden]: isHidden
        })}
        imgixParams={{ fit: 'clip' }}
        src={image}
        sizes="100vw"
      />
    )
  }
}

Image.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired
}

export default Image
