import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'

import styles from './review.css'

import AWARD_RATING_FRAGMENT from '../../../../graphql/fragments/award-rating.gql'

function Review({ review, isRental }) {
  return (
    <div className={styles.review}>
      <div className={styles.value}>
        {review.value}
      </div>
      <div className={classNames(styles.name, {
        [styles.isRental]: isRental
      })}
      >
        {review.name ? review.name : ''}
      </div>
    </div>
  )
}

Review.propTypes = {
  review: propType(AWARD_RATING_FRAGMENT).isRequired,
  isRental: PropTypes.bool
}

Review.defaultProps = {
  isRental: false
}

export default Review
