import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  video: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    transition: 'opacity 1s ease-out'
  },
  videoRatio: {
    '&:before': {
      paddingTop: '56.25%',
      content: '""',
      display: 'block'
    },
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    top: '50%',
    transform: 'translate(0, -50%)'
  },
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  videoPlayer: {
    height: '100%',
    width: '100%'
  },
  hidden: {
    opacity: 0
  }
})

export default useStyles
