import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { format } from 'date-fns'

import { SERIES_CONFIG } from '../../constants'
import styles from './upcoming-episode.css'

function UpcomingEpisode({
  startDate, mobile, title, isRental
}) {
  const upcomingEpisodeDate = mobile ? format(new Date(startDate), 'MMM do') : format(new Date(startDate), 'MMMM do')

  const upcomingTitle = format(new Date(startDate), 'd MMMM yyyy')
  return (
    <div
      className={classNames(
        {
          [styles.upcomingEpisode]: !title,
          [styles.upcomingTitle]: title,
          [styles.isRental]: isRental
        }
      )}
    >
      <span
        className={classNames(
          {
            [styles.comingSoon]: !title,
            [styles.comingSoonTitle]: title,
            [styles.isRental]: isRental
          }
        )}
      >
        {SERIES_CONFIG.comingSoonEpisode}
      </span>
      {
        title
          ? (
            <span className={classNames(styles.startDateTitle, {
              [styles.isRental]: isRental
            })}
            >
              <span className={styles.available}>Available&nbsp;</span>
              {upcomingTitle}
            </span>
          )
          : <span className={styles.startDate}>{upcomingEpisodeDate}</span>
      }
    </div>
  )
}

UpcomingEpisode.propTypes = {
  startDate: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  title: PropTypes.bool,
  isRental: PropTypes.bool
}
UpcomingEpisode.defaultProps = {
  mobile: false,
  title: false,
  isRental: false
}

export default UpcomingEpisode
