import React, {
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'
import { useMutation } from 'react-apollo'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ControlStream from './control-stream'
import Image from './image'
import { loadScript } from '../../../lib/script-loader'

import styles from './autoplay-trailer.css'
import useStyles from './autoplay-trailer-stream.styles'
import { AUTOPLAY_TRAILER_PLAYER } from '../../../constants'

import PLAYBACK_AUTH from '../../../../graphql/mutations/playback-auth.gql'
import { getDRMSystem } from './utils/drm'
import { getOperatingSystem } from '../../../utils/os-device'

const PLAYER_ID = process.env.BC_PLAYER_ID
const ACCOUNT_ID = process.env.BC_ACCOUNT_ID
const scriptUrl = `https://players.brightcove.net/${ACCOUNT_ID}/${PLAYER_ID}_default/index.min.js`

const AutoplayTrailerStream = ({ content, image }) => {
  const videoElem = useRef(null)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [player, setPlayer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [shouldHideImage, setShouldHideImage] = useState(false)
  const [drmSystem, setDrmSystem] = useState(null)
  const classes = useStyles()
  const { name, version } = getOperatingSystem()

  const { id } = content
  let onLoadedTimer

  // Load Script, drm
  useEffect(() => {
    (async () => {
      if (!scriptLoaded) {
        loadScript(scriptUrl, () => {
          setScriptLoaded(true)
        })
      }

      if (!drmSystem) {
        const drm = await getDRMSystem()
        setDrmSystem(drm)
      }
    })()
  }, [])

  // Get playback auth
  useEffect(() => {
    if (!drmSystem) {
      return
    }

    playbackAuth({
      variables: {
        drmLevel: drmSystem,
        os: name,
        osVersion: version,
        contentItemId: id
      }
    })
  }, [drmSystem])

  const [playbackAuth, { data: playbackAuthData }] = useMutation(PLAYBACK_AUTH)

  // Load player
  useEffect(() => {
    if (!scriptLoaded || !videoElem.current || !playbackAuthData) {
      return
    }

    setPlayer(window.bc(videoElem.current))
  }, [scriptLoaded, videoElem.current, playbackAuthData])

  // Load media and set event handlers
  useEffect(() => {
    if (!player || !playbackAuthData) {
      return undefined
    }
    const videoProcessed = player.catalog.transformVideoResponse(playbackAuthData.playAuth)
    player.catalog.load(videoProcessed)
    player.autoplay(false)

    player.on('play', onPlay)
    player.on('pause', onPause)
    player.on('loadeddata', onLoaded)
    player.on('ended', onEnded)

    return () => {
      player.off('play', onPlay)
      player.off('pause', onPause)
      player.off('loadeddata', onLoaded)
      player.off('ended', onEnded)
      player.dispose()
    }
  }, [player, playbackAuthData])

  // Event Handlers
  const onPlay = useCallback(() => {
    setIsPlaying(true)
    setShouldHideImage(true)
  }, [setIsPlaying, setShouldHideImage])

  const onPause = useCallback(() => {
    setIsPlaying(false)
  }, [setIsPlaying])

  const onEnded = useCallback(() => {
    setIsPlaying(false)
    setShouldHideImage(false)
  }, [
    setIsPlaying,
    setShouldHideImage
  ])

  const onLoaded = useCallback(() => {
    let { delaySeconds } = content

    if (!delaySeconds) {
      delaySeconds = 0
    }

    onLoadedTimer = setTimeout(() => {
      if (!player) return
      player.muted(true)
      player.play()
    }, delaySeconds * 1000)
  }, [content, player])

  // Remove timeout when unmount
  useEffect(() => {
    return () => {
      if (onLoadedTimer) {
        clearTimeout(onLoadedTimer)
      }
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.videoWrapper}>
        <ControlStream player={player} isPlaying={isPlaying} />

        <Image isHidden={shouldHideImage} image={image} />

        <div
          className={classnames(classes.video, {
            [classes.hidden]: !shouldHideImage
          })}
        >
          <div className={classes.videoRatio}>
            <div className={classes.videoContainer}>
              <video
                id={AUTOPLAY_TRAILER_PLAYER.id}
                className={classnames('video-js', classes.videoPlayer)}
                ref={videoElem}
              />
            </div>
          </div>
        </div>

        <div className={styles.videoLeftGradient} />
        <div className={styles.videoBottomGradient} />
      </div>
    </div>
  )
}

export const AutoplayTrailerContentType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  delaySeconds: PropTypes.number
})

AutoplayTrailerStream.propTypes = {
  content: AutoplayTrailerContentType.isRequired,
  image: PropTypes.string.isRequired
}

AutoplayTrailerStream.defaultProps = {
  content: undefined,
  image: undefined
}

export default AutoplayTrailerStream
