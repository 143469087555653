import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { pathOr } from 'ramda'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import { DeepLink } from '../../../hoc/deep-link'
import Imgix from '../../imgix'

import styles from './trailer.css'
import play from '../../../../images/new-play.svg'

import VIDEO_EXTRA_FRAGMENT from '../../../../graphql/fragments/video-extra.gql'
import CONTENT_ITEM_FRAGMENT from '../../../../graphql/fragments/content-item.gql'

function TrailerThumbnail({
  trailer,
  trailerUrl,
  onClick,
  contentItem
}) {
  const [isMouseHover, setIsMouseHover] = useState(false)

  return (
    <div
      className={styles.trailer}
      key={trailer.id}
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
    >
      <div className={classNames(styles.imgWrapper, {
        [styles.isRental]: contentItem.isRental
      })}
      >
        <DeepLink
          to={trailerUrl}
          onClick={onClick}
        >
          <Imgix
            src={pathOr('', ['tile', 'image'], trailer)}
            recipe="trailer"
            className={styles.thumbnail}
          />
          { isMouseHover && (
            <div className={styles.playWrapper}>
              <div className={styles.iconWrapper}>
                <ReactSVG
                  src={play}
                  alt="Play"
                  beforeInjection={(svg) => {
                    svg.setAttribute('class', styles.playImg)
                  }}
                  className={styles.navArrow}
                />
              </div>
            </div>
          )}
        </DeepLink>
      </div>
      <div className={styles.title}>{trailer.title ? trailer.title : ''}</div>
    </div>
  )
}

TrailerThumbnail.propTypes = {
  trailer: propType(VIDEO_EXTRA_FRAGMENT).isRequired,
  trailerUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired
}

export default TrailerThumbnail
