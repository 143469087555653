import React from 'react'
import PropTypes from 'prop-types'

function IconPause({ wrapperClassName, ...props }) {
  return (
    <div className={wrapperClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="white"
        {...props}
      >
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
      </svg>
    </div>
  )
}

IconPause.propTypes = {
  wrapperClassName: PropTypes.string
}

IconPause.defaultProps = {
  wrapperClassName: undefined
}

export default IconPause
