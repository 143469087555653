import PropTypes from 'prop-types'
import {
  isEmpty,
  isNil,
  path,
  pathOr,
  propOr
} from 'ramda'
import { propType } from 'graphql-anywhere'
import { graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  branch,
  compose,
  defaultProps,
  lifecycle,
  renderNothing,
  setPropTypes,
  withProps
} from 'recompose'

import WatchButton from '../../components/watch-button'
import withCastSender from '../../hoc/with-cast-sender'
import withIsAuthenticated from '../../hoc/is-authenticated'
import withInitializeCastSender from '../../hoc/with-castPlayer-sender-initialize'

import { THEME_OPTIONS } from '../../constants'

import PLAYBACK_INFO_QUERY from '../../../graphql/queries/playback-info.gql'
import CONTENT_ITEM_QUERY from '../../../graphql/queries/content-item.gql'
import MY_RENTALS_QUERY from '../../../graphql/queries/my-rentals.gql'
import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item-light.gql'
import {
  isEpisode,
  isSeries,
  isTitle
} from '../../lib/content'
import { getRentalStatus } from '../../lib/content-item'
import {
  PLAY_BUTTON_IN_PLAYER_EPISODE_SELECTOR,
  PLAY_BUTTON_IN_SERIES_EPISODE_SELECTOR,
  PLAY_BUTTON_IN_SERIES_HERO,
  PLAY_BUTTON_IN_TITLE_HERO
} from './constants'

import { getSelectedProfileIdFromSession } from '../../lib/account'

import {
  getTitlePlaybackInfo,
  getTitleProps
} from './title'
import {
  getEpisodeToPlay,
  getSeriesPlaybackInfo,
  getSeriesProps
} from './series'
import {
  getEpisodePlaybackInfo,
  getEpisodeProps
} from './episode'
import { withCurrentProfile } from '../../hoc/with-current-profile'

const mapStateToProps = ({ theme, session }) => {
  return {
    theme,
    isKid: pathOr(false, ['decodedJwt', 'isKid'], session),
    profileId: getSelectedProfileIdFromSession(session)
  }
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withIsAuthenticated,
  withInitializeCastSender,
  withCastSender,
  setPropTypes({
    displayCta: PropTypes.bool,
    displayPrice: PropTypes.bool,
    displayProgress: PropTypes.bool, // TODO: handle this
    playbackInfo: PropTypes.shape({
      items: PropTypes.oneOfType([PropTypes.array])
    }),
    contentItem: propType(CONTENT_ITEM_FRAGMENT),
    displayedInLocation: PropTypes.oneOf([
      PLAY_BUTTON_IN_SERIES_HERO,
      PLAY_BUTTON_IN_TITLE_HERO,
      PLAY_BUTTON_IN_PLAYER_EPISODE_SELECTOR,
      PLAY_BUTTON_IN_SERIES_EPISODE_SELECTOR
    ])
  }),
  defaultProps({
    displayCta: true,
    displayPrice: true,
    displayProgress: true,
    playbackInfo: null,
    displayedInLocation: null
  }),
  withCurrentProfile,
  graphql(CONTENT_ITEM_QUERY, {
    name: 'contentItemQuery',
    options: ({ contentItem }) => {
      return {
        variables: {
          id: contentItem.id
        },
        notifyOnNetworkStatusChange: true
      }
    },
    skip: ({ contentItem }) => {
      if (isEpisode(contentItem) && !isNil(contentItem.isComingSoon)) {
        return true
      }
      return (
        !(isNil(contentItem.products) || isEmpty(contentItem.products)) &&
        !(isNil(contentItem.isComingSoon) || isEmpty(contentItem.isComingSoon))
      )
    }
  }),
  graphql(MY_RENTALS_QUERY, {
    name: 'myRentalsQuery',
    skip: ({ isAuthenticated }) => !isAuthenticated
  }),
  graphql(PLAYBACK_INFO_QUERY, {
    name: 'playbackInfoQuery',
    options: ({ contentItem }) => {
      return {
        variables: {
          contentItemId: contentItem.id
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    },
    skip: ({ isAuthenticated, playbackInfo }) => {
      return !isAuthenticated || !isNil(playbackInfo)
    }
  }),
  // render nothing until content item and playback info is fetched
  branch(
    ({ contentItemQuery, myRentalsQuery, playbackInfoQuery }) => (
      (contentItemQuery && (contentItemQuery.error || contentItemQuery.loading)) ||
      (myRentalsQuery && (myRentalsQuery.error || myRentalsQuery.loading)) ||
      (playbackInfoQuery && (playbackInfoQuery.error || playbackInfoQuery.loading))
    ),
    renderNothing
  ),
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (pathOr(false, ['playbackInfoQuery', 'playbackInfo', 'watchable'], nextProps)) {
        return
      }

      const location = this.props.location
      const nextLocation = nextProps.location

      if (this.props.playbackInfoQuery && location.search !== nextLocation.search) {
        // Will refetch playback info on route change from modal to no modal page
        this.props.playbackInfoQuery.refetch({
          contentItemId: this.props.contentItem.id
        })
      }
    }
  }),
  withProps(
    ({
      contentItem = {},
      contentItemQuery,
      myRentalsQuery: { myRentalHistory } = {},
      playbackInfo,
      playbackInfoQuery,
      theme
    }) => {
      const item = propOr(contentItem, 'contentItem', contentItemQuery)
      return {
        contentItem: item,
        displayProgress: (theme === THEME_OPTIONS.dark),
        watchable: pathOr(false, ['watchable'], playbackInfo) || pathOr(false, ['playbackInfo', 'watchable'], playbackInfoQuery),
        rentalStatus: getRentalStatus(item, myRentalHistory),
        playbackInfoArray: path(['items'], playbackInfo) || pathOr([], ['playbackInfo', 'items'], playbackInfoQuery),
        isRental: pathOr(false, ['isRental'], item)
      }
    }
  ),
  // TITLE specific logic
  branch( // is title and not comming soon
    ({ contentItem }) => {
      return (!contentItem.isComingSoon && isTitle(contentItem))
    },
    branch(
      // is title with no products
      ({ contentItem }) => isNil(contentItem.products) || isEmpty(contentItem.products),
      renderNothing, // render nothing
      compose( // proceed as normal
        withProps(getTitlePlaybackInfo),
        withProps(getTitleProps)
      )
    )
  ),
  // SERIES specific logic
  branch(
    ({ contentItem }) => (!contentItem.isComingSoon && isSeries(contentItem)),
    branch(
      ({ contentItem }) => isEmpty(contentItem.seasons),
      renderNothing, // No episodes to play!
      compose(
        withProps(getEpisodeToPlay),
        withProps(getSeriesPlaybackInfo),
        withProps(getSeriesProps)
      )
    )
  ),
  // EPISODE specific logic
  branch(
    ({ contentItem }) => !contentItem.isComingSoon && isEpisode(contentItem),
    compose(
      withProps(getEpisodePlaybackInfo),
      withProps(getEpisodeProps)
    )
  ),
  // Coming Soon overrides
  branch(
    // render nothing if series or only first episode (new show) has isComingSoon
    ({ contentItem, episodeToPlay }) => contentItem.isComingSoon || (!episodeToPlay && path(['seasons', 0, 'episodes', 0, 'isComingSoon'], contentItem)),
    renderNothing
  )
)

export default enhance(WatchButton)
