import React from 'react'
import PropTypes from 'prop-types'

function IconPlay({ wrapperClassName, ...props }) {
  return (
    <div className={wrapperClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="white"
        {...props}
      >
        <path d="M8 5v14l11-7z" />
      </svg>
    </div>
  )
}

IconPlay.propTypes = {
  wrapperClassName: PropTypes.string
}

IconPlay.defaultProps = {
  wrapperClassName: undefined
}

export default IconPlay
