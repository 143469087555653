import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import styles from './cast-crew.css'

import TITLE_FRAGMENT from '../../../../graphql/fragments/title.gql'
import SERIES_FRAGMENT from '../../../../graphql/fragments/series.gql'

function CastCrew({ contentItem }) {
  const isMovie = contentItem.__typename === 'Title'
  const actors = contentItem.actors
    ? contentItem.actors
      .sort((a, b) => a.billing - b.billing)
      .slice(0, 8)
      .map(actor => actor.name)
      .join(', ')
    : ''
  const directors = contentItem.directors
    ? contentItem.directors
      .sort((a, b) => a.billing - b.billing)
      .slice(0, 8)
      .map(director => director.name)
      .join(', ')
    : ''

  return actors.length + directors.length > 0 ? (
    <div className={styles.castCrewComponentWrapper}>
      {/* <div className={styles.castCrewHeader}>Cast & Crew</div> */}
      <div className={styles.castCrewBody}>
        {actors.length > 0 && (
          <div>
            Starring:&nbsp;
            {actors}
          </div>
        )}
        {directors.length > 0 && isMovie && (
          <div>
            Directors:&nbsp;
            {directors}
          </div>
        )}
      </div>
    </div>
  ) : null
}

CastCrew.propTypes = {
  contentItem: PropTypes.oneOfType([
    propType(TITLE_FRAGMENT),
    propType(SERIES_FRAGMENT)
  ]).isRequired
}

export default CastCrew
