import React from 'react'
import PropTypes from 'prop-types'

import AsyncReactTippy from '../../containers/async-react-tippy/index'
import { AGE_RATINGS_HELP_ARTICLE } from '../../constants'

import styles from './ratings-tooltip.css'

function RatingsReasonsTooltip({ reason, children }) {
  return (
    <AsyncReactTippy
      html={(
        <div className={styles.ratingTooltip}>
          <p>{reason}</p>
          <a href={AGE_RATINGS_HELP_ARTICLE} target="_blank" rel="noopener noreferrer"><strong>Find out more</strong></a>
        </div>
      )}
      position="top"
      trigger="mouseenter"
      hideOnClick={false}
      interactive
      interactiveBorder="50"
      arrow
      duration="0"
      className={styles.seriesRatingWrapper}
    >
      {children}
    </AsyncReactTippy>
  )
}

RatingsReasonsTooltip.propTypes = {
  reason: PropTypes.string.isRequired
}

export default RatingsReasonsTooltip
