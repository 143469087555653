import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import Review from './review'
import styles from './reviews.css'

import AWARD_RATING_FRAGMENT from '../../../../graphql/fragments/award-rating.gql'

function Reviews({ reviews, isRental }) {
  return (
    reviews && reviews.length
      ? (
        <div className={styles.reviewsWrapper}>
          <div className={styles.columnsWrapper}>
            <div className={styles.reviewsHeader}>
              Awards
            </div>
            <div className={styles.reviews}>
              {
                reviews.slice(0, 3).map((review) => {
                  return (
                    <Review review={review} key={review.name} isRental={isRental} />
                  )
                })
              }
            </div>
          </div>
        </div>
      )
      : null
  )
}

Reviews.propTypes = {
  reviews: PropTypes.arrayOf(propType(AWARD_RATING_FRAGMENT)).isRequired,
  isRental: PropTypes.bool
}

Reviews.defaultProps = {
  reviews: [],
  isRental: false
}

export default Reviews
