import PropTypes from 'prop-types'
import {
  compose,
  setPropTypes,
  withHandlers,
  withProps
} from 'recompose'
import { always } from 'ramda'
import { connect } from 'react-redux'

import TrailerThumbnailComponent from '../../../components/shared/trailers/trailer-thumbnail'
import ViewTrailerButtonComponent from '../../../components/shared/trailers/view-trailer-button'
import {
  getShowTitleFromContentItem,
  navigationGa
} from '../../../lib/analytics/ga'
import {
  customDimensions,
  getCategoryNameFromContentItem
} from '../../../lib/analytics/custom-dimensions'
import withCastSender from '../../../hoc/with-cast-sender'
import withInitializeCastSender from '../../../hoc/with-castPlayer-sender-initialize'
import { getSelectedProfileIdFromSession } from '../../../lib/account'
import { getAdditionalGenreFromContentItem } from '../../../lib/analytics/datalayer'

const mapStateToProps = ({ session }) => ({
  profileId: getSelectedProfileIdFromSession(session)
})

const enhance = compose(
  setPropTypes({
    renderedInComponent: PropTypes.string.isRequired
  }),
  connect(mapStateToProps, always({})),
  withInitializeCastSender,
  withCastSender,
  withProps(({
    id,
    contentItem
  }) => ({
    trailerUrl: `/trailer/${id}/${contentItem.id}`
  })),
  withHandlers({
    onClick: ({
      trailerUrl,
      contentItem,
      profileId,
      castSender,
      maybeInitializeCastSender,
      trailer,
      renderedInComponent
    }) => (event) => {
      navigationGa({
        category: 'trailerWatched',
        action: getShowTitleFromContentItem(contentItem),
        label: trailerUrl,
        [customDimensions.CategoryName]: getCategoryNameFromContentItem(contentItem),
        [customDimensions.ProfileId]: profileId,
        [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(contentItem),
        [customDimensions.ComponentName]: renderedInComponent
      })

      // Let Link handle the click and location change
      // if cast sender is disconnected
      if (!castSender.isConnected) { return }

      // PreventLink from handling the click
      event.preventDefault()

      maybeInitializeCastSender(trailerUrl, trailer || contentItem, true)
    }
  })
)

export const ViewTrailerButton = enhance(ViewTrailerButtonComponent)
export const TrailerThumbnail = enhance(TrailerThumbnailComponent)
