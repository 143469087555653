import React from 'react'
import PropTypes from 'prop-types'

import { Rating } from '../rating'
import styles from './ratingDetails.css'

export const RatingDetails = ({
  ratingContent,
  reason
}) => {
  return (
    <div className={styles.ratingDetails}>
      <Rating rating={ratingContent} className={styles.ratingLabel} />
      <span className={styles.ratingReason}>{reason}</span>
    </div>
  )
}

RatingDetails.propTypes = {
  ratingContent: PropTypes.shape({
    rating: PropTypes.string.isRequired,
    reason: PropTypes.string
  }).isRequired,
  reason: PropTypes.string
}
