import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'

import { DeepLink } from '../../../hoc/deep-link'
import CONTENT_ITEM_FRAGMENT from '../../../../graphql/fragments/content-item.gql'

import styles from './view-trailer-button.css'

const ViewTrailerButton = ({
  contentItem,
  trailerUrl,
  onClick
}) => {
  return (
    <DeepLink
      className={classNames(styles.viewTrailerButton, {
        [styles.isRental]: contentItem.isRental
      })}
      onClick={onClick}
      to={trailerUrl}
    >
      <div>
        TRAILER
      </div>
    </DeepLink>
  )
}

ViewTrailerButton.propTypes = {
  trailerUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired
}

export default ViewTrailerButton
