import React from 'react'
import PropTypes from 'prop-types'
import IconPlay from './icons/icon-play'
import IconPause from './icons/icon-pause'
import styles from './control-center.css'

class ControlCenter extends React.PureComponent {
  render() {
    const {
      wrapperClassName, play, pause, isPlaying
    } = this.props
    return (
      <div
        className={wrapperClassName}
        onClick={() => {
          if (isPlaying) {
            pause()
          } else {
            play()
          }
        }}
      >
        {isPlaying ? (
          <IconPlay wrapperClassName={styles.circle} />
        ) : (
          <IconPause wrapperClassName={styles.circle} />
        )}
      </div>
    )
  }
}

ControlCenter.propTypes = {
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  wrapperClassName: PropTypes.string.isRequired
}

export default ControlCenter
