import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useGetWatchLocation } from '../../hooks/useGetWatchLocation'
import styles from './watch-button.css'
import { DeepLink } from '../../hoc/deep-link'
import ConfirmationModal from '../shared/confirmation-modal'
import { KIDS_PROFILE_ADD_SUBSCRIPTION_ERROR_MESSAGE } from '../../constants'

function WatchButton({
  ctaPrefix,
  ctaSuffix,
  price,
  displayPrice,
  onClick,
  clickable,
  watchUrl,
  className,
  skipIntro,
  fromEpisodeCountdown,
  isRental,
  isKid,
  watchable
}) {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const clickableClass = clickable ? styles.clickable : styles.notClickable
  const watchLocation = useGetWatchLocation(watchUrl, skipIntro, isKid, watchable)

  const handleOnClick = () => {
    if (isKid && !watchable) {
      setShowErrorMessage(true)
    }

    onClick()
  }

  const onConfirmClick = () => {
    setShowErrorMessage(false)
  }

  if (showErrorMessage) {
    return (
      <ConfirmationModal
        header="Hmmm"
        message={KIDS_PROFILE_ADD_SUBSCRIPTION_ERROR_MESSAGE}
        confirmText="OK"
        onConfirmClick={onConfirmClick}
        customWrapperClass={styles.errorMessageWrapper}
      />
    )
  }

  // REMOVE ME, this is for outlander issue only, remove after season 5 is out
  if (!watchUrl && /\/series\/outlander/.test(location.pathname)) {
    return null
  }

  return (
    <div
      className={classNames(
        styles.buttonWrapper,
        clickableClass
      )}
      data-e2e="watch-button-on-cta-box"
    >
      <DeepLink
        isPlayButton
        to={watchLocation}
        onClick={handleOnClick}
        className={classNames(
          clickableClass,
          className
        )}
      >
        <div
          className={classNames(
            styles.buttonCta,
            clickableClass,
            {
              [styles.isRental]: isRental
            }
          )}
        >
          {ctaPrefix}
          {ctaSuffix && (
            <span className={styles.buttonEpisodeNumber}>
              {' '}
              {ctaSuffix}
            </span>
          )}
          {
            displayPrice && (
              <span className={classNames(styles.buttonPrice, {
                [styles.isRental]: isRental
              })}
              >
                {' '}
                {price}
              </span>
            )
          }
        </div>
      </DeepLink>
    </div>
  )
}

WatchButton.propTypes = {
  watchUrl: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.object]),
    PropTypes.string
  ]),
  ctaPrefix: PropTypes.string,
  ctaSuffix: PropTypes.string,
  price: PropTypes.string,
  displayPrice: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  className: PropTypes.string,
  skipIntro: PropTypes.bool,
  fromEpisodeCountdown: PropTypes.bool,
  isRental: PropTypes.bool.isRequired,
  isKid: PropTypes.bool,
  watchable: PropTypes.bool
}

WatchButton.defaultProps = {
  ctaPrefix: '',
  ctaSuffix: '',
  price: '',
  clickable: true,
  onClick: () => { },
  watchUrl: '#',
  className: '',
  skipIntro: false,
  fromEpisodeCountdown: false,
  isRental: false,
  isKid: false,
  watchable: false
}

export default WatchButton
