import React from 'react'
import { map, compose, defaultTo } from 'ramda'

export const openGraphMetaImages = compose(map((image) => {
  return [
    <meta property="og:image" content={image.uri} />,
    <meta property="og:image:width" content={image.width} />,
    <meta property="og:image:height" content={image.height} />
  ]
}), defaultTo([]))
