import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconPlay from './icons/icon-play'
import IconPause from './icons/icon-pause'
import styles from './control-play.css'

class ControlPlay extends React.PureComponent {
  render() {
    const {
      play,
      pause,
      isPlaying,
      wrapperClassName,
      iconWrapperClassName,
      iconClassName
    } = this.props

    return (
      <div
        className={wrapperClassName}
        onClick={() => {
          if (isPlaying) {
            pause()
          } else {
            play()
          }
        }}
      >
        <IconPlay
          wrapperClassName={classnames(
            styles.iconWrapper,
            iconWrapperClassName,
            {
              [styles.hide]: isPlaying
            }
          )}
          className={iconClassName}
        />
        <IconPause
          wrapperClassName={classnames(
            styles.iconWrapper,
            iconWrapperClassName,
            {
              [styles.hide]: !isPlaying
            }
          )}
          className={iconClassName}
        />
      </div>
    )
  }
}

ControlPlay.propTypes = {
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
  iconClassName: PropTypes.string.isRequired,
  iconWrapperClassName: PropTypes.string.isRequired
}

export default ControlPlay
