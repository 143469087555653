import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import classNames from 'classnames'
import styles from './leaving-soon-title.css'

export const LeavingSoonTitle = ({ endDate, isRental }) => {
  const title = format(new Date(endDate), 'd MMMM yyyy')

  return (
    <div className={styles.title}>
      <span
        className={classNames(styles.status, {
          [styles.isRental]: isRental
        })}
      >
        { isRental ? 'Rental Period' : 'Leaving Soon' }
        {' '}
&nbsp;
      </span>

      <span className={styles.desc}>
        <span>Available until&nbsp;</span>
        {title}
      </span>
    </div>
  )
}

LeavingSoonTitle.propTypes = {
  endDate: PropTypes.string.isRequired,
  isRental: PropTypes.bool
}
